import { render, staticRenderFns } from "./MarketingHome.vue?vue&type=template&id=3ffb2622&scoped=true"
import script from "./MarketingHome.vue?vue&type=script&lang=js"
export * from "./MarketingHome.vue?vue&type=script&lang=js"
import style0 from "./MarketingHome.vue?vue&type=style&index=0&id=3ffb2622&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffb2622",
  null
  
)

export default component.exports