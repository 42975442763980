class RegistryNamesMappings {
  constructor() {
    this.nameMappings = {
      // many
      'promos': 'Modelli Promozioni',
      'smsModels': 'Modelli Whatsapp-SMS',
      'automationMarketing': 'Automation Marketing',
      'wheelOfFortune': 'Preset Ruota Della Fortuna',
      'sms': 'Impostazioni SMS',
      'intermediary': "Procaccia Affari",
      'intermediarySetting': "Impostazioni Procacciatore d'affari",
      'news': 'Guarda News',
      'smsRegister' : "Registro SMS",
      'promo': 'Promozione',
      'sendMassiveSms': 'Invia Whatsapp e SMS',
      'bookingSetting': 'Impostazioni Booking',
      'bookingVoucher': 'Convalida Voucher',
      'calendarMarketing': 'Programma Calendario Marketing',
      'smsModelPreset': 'Modelli SMS / Whatsapp',
      'receiptModelPreset': 'Modelli Scontrino',
      'editorialCalendar': 'Calendario Editoriale',
      'managementCalendar': 'Calendario Management',
      'sendNewsletter': 'Invia Newsletter',
      'sendEmail': 'Invia Email',
      'sendNews': 'Invia News',
    }

    this.iconMarketingMappings = {
      // many
      'promos': 'mdi-tag-text-outline',
      'smsModels': 'mdi-forum',
      'automationMarketing': 'mdi-lightbulb',
      'wheelOfFortune': 'mdi-dharmachakra',
      'sms': 'mdi-message-cog',
      'intermediary': 'mdi-account-cowboy-hat',
      'news': 'mdi-newspaper',
      'smsRegister': 'mdi-forum',
      'promo': 'mdi-tag-text-outline',
      'intermediarySetting': 'mdi-cog',
      'sendMassiveSms': 'mdi-message-text-fast',
      'bookingSetting': 'mdi-store-search-outline',
      'bookingVoucher': 'mdi-ticket-confirmation-outline',
      'calendarMarketing': 'mdi-calendar-blank-multiple',
      'smsModelPreset': 'mdi-message',
      'receiptModelPreset': 'mdi-receipt',
      'editorialCalendar': 'mdi-calendar-text',
      'managementCalendar': 'mdi-calendar-account',
      'sendNewsletter': 'mdi-email-newsletter',
      'sendEmail': 'mdi-email-fast',
      'sendNews': 'mdi-send',
    }

    this.iconOperationsMappings = {
      // many
      'add': 'mdi-plus',
      'edit': 'mdi-pencil',
      'view': 'mdi-eye-outline',
    }
  }

  getHumanName(codedName) {
    return this.nameMappings[codedName]
  }

  getCodedName(humanName) {
    return Object.keys(this.nameMappings).find(key => this.nameMappings[key] === humanName);
  }

  getRegistryIcon(registryName) {
    return this.iconMarketingMappings[registryName]
  }

  getOperationIcon(operationName) {
    return this.iconOperationsMappings[operationName]
  }
}

export default new RegistryNamesMappings()
