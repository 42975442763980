<template>
  <div class="">
    <TitleWithBackButton
      title="Marketing"
      back-route="Homepage"
      icon="mdi-shopping-outline"
    ></TitleWithBackButton>
    <MarketingHome
      :style="{
        'pointer-events': canManageMarketing ? 'all' : 'none',
        opacity: canManageMarketing ? '1' : '0.2',
      }"
    ></MarketingHome>
    <ErrorDialogComponent
      v-if="showErrorDialog"
      :open-dialog="showErrorDialog"
      title="Utente non abilitato"
      :error-message="errorMessage"
      @close-error="goBack"
    >
    </ErrorDialogComponent>
  </div>
</template>

<script>
import MarketingHome from "@/components/areas/marketing/MarketingHome.vue";
import TitleWithBackButton from "@/components/common/TitleWithBackButton.vue";
import ErrorDialogComponent from "@/components/common/errorComponent/ErrorDialogComponent.vue";

export default {
  components: {
    MarketingHome,
    TitleWithBackButton,
    ErrorDialogComponent,
  },
  data: function () {
    return {
      showErrorDialog: false,
      errorMessage: "",
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Homepage" });
    },
  },
  computed: {
    canManageMarketing() {
      if (
        this.marketingPermission.includes("view")
      )
        return true;
      else {
        this.showErrorDialog = true;
        this.errorMessage = "Accesso al marketing non autorizzato ";
        return false;
      }
    },
  },
  permissions: {
    Marketing: {
      bind: "marketingPermission",
    },
  },
};
</script>

<style>
</style>