
import marketingList from './marketing.json'
import RegistryNamesMappings from './marketingNameMappings'

class registriesManagment {
  constructor() {
    this.marketingSections = marketingList
    this._calculateRegistriesText()
  }

  list() {
    return Promise.resolve(this.marketingSections)
  }


  _calculateRegistriesText() {
    for (let i = 0; i < this.marketingSections.length; i++) {
      this.marketingSections[i].text = RegistryNamesMappings.getHumanName(this.marketingSections[i].name)
      this.marketingSections[i].icon = RegistryNamesMappings.getRegistryIcon(this.marketingSections[i].name)
    }
  }
}

export default new registriesManagment()